.current-post{

    .current-card{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.5rem;
        
        .post{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            background-color: white;
            border-radius: .6rem;
            padding: 2.5rem;

            .user-info{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .user{
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .user-img img{
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                
            }

            

            .post-detail{
                padding-left: 5rem;
                .post-title{
                    font-weight: bold;
                    font-size: 1.6rem;
                }
                
                .post-img{
                    img{
                        margin-top: 1rem;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }


            .post-action{
                display: flex;
                gap: 1.5rem;
                padding-left: 5rem;

                .reply{
                    text-decoration: none;
                    color: black;   
                }

                .unlike, .like{
                    display: flex;
                    align-items: center;
                    gap: .4rem;
                }
            }

            .reply-comment{
                display: flex;
                justify-content: space-between;
                padding-left: 5rem;

                input{
                    width: 84%;
                    height: 3.4rem;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 2px solid #F4F3F3;
                    padding: 1rem;
                    &::placeholder{
                       color: #AAAAAA;;
                    }
                }

                button{
                    width: 15%;
                    height: 3.4rem;
                    background-color: #04AA6D;
                    border: none;
                    color: white;
                    text-decoration: none;
                    cursor: pointer;
                }

            }
        }

        .comments{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            .comment-card{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                background-color: white;
                border-radius: .6rem;
                padding: 2.5rem;
    
                .user-info{
                    display: flex;
                    align-items: center;
                    gap: 1rem;
    
                    img{
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .comment-detail, .comment-action{
                    padding-left: 5rem;
                }

                
                .comment-action{
                    display: flex;
                    gap: 1.5rem;
                    margin-top: 1rem;

                    .reply{
                        cursor: pointer;
                    }

                    .unlike, .like, .replys{
                        display: flex;
                        align-items: center;
                        gap: .4rem;
                    }
                }

                .comment-replys{
                    padding-left: 5rem;
                    margin-top: .5rem;
                   .reply-user-info{
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        img{
                        width: 2.7rem;
                        height: 2.7rem;
                        object-fit: cover;
                        border-radius: 50%;
                        }
                   }

                   .reply-detail{
                    padding-left: 3.75rem;
                    margin-top: 1rem;

                   }

                   .reply-action{
                        display: flex;
                        gap: 1.5rem;
                        margin-top: 1rem;
                        padding-left: 3.75rem;
                   }
                }
            }
        }
        
    }
}

