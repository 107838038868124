.posts{
    .post-category{
        display: flex;
        margin-bottom: .3rem;
        .popular, .recents{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 3rem;
            font-size: 1.7rem;
            cursor: pointer;            
        }

        .active{
            border-bottom: 3px solid #03B425;
        }
    }

    .all-post{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        

        .post-card{
            background-color: white;
            border-radius: .5rem;
            padding: 1.3rem;
            

            

            .post-card-title{
                font-size: 1.7rem;
                height: 6.5rem;
                cursor: pointer;
            }
            
            .post-card-action{
                display: flex;
                justify-content: space-between;

                .post-card-action-left{
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;

                    span{
                        font-size: 1.3rem;
                    }
                  
                }

                .post-card-action-right{
                    font-size: 1.3rem;
                }
            }
        }

        .active-card{
            background-color: rgb(207, 207, 207);
        }
    }
}

.threeDot{
    float: right;
    li{
        float: left;
        font-size: 1.3rem;
        padding: .5rem;
        color: white;
        border-radius: 0.5rem;
        background-color: rgb(184, 42, 42);
        margin-top: 1.5rem;
        visibility: hidden;
        cursor: pointer;
    }

    &:hover{
        li{
            visibility: visible;
        }
    }
}