.users{
    display: flex;
    justify-content: center;

    .users-title{
        width: 36rem;
        height: 6rem;
        background: #16232C 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        font-size: 1.7rem;
        display: flex;
        align-items: center;
        padding-left: 2.6rem;
    }

    .popular-users{
        .user-card{
            width: 36rem;
            height: 7.3rem;
            border-bottom: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            padding-left: 2.6rem;
            font-size: 1.6rem;
            background-color: white;
            
            img{
                width: 4.5rem;
                height: 4.5rem;
                object-fit: cover;
                border-radius: 50%;
            }

            .user-info{
                span{
                    font-size: 1.4rem;
                    color: #9B9B9B;
                }
            }
        }
    }
}