@media only screen and (max-width: 1800px){
    .navbar{
        .navbar-container{
            margin-left: 3rem;
            margin-right: 3rem;

            .add-post{
                display: none;
            }
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;

        .posts, .current-post, .users{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 625px){
    .navbar{
        .navbar-container{
            .search{
                input{
                    display: none;
                }
            }
        }
        
    }
}