.navbar{
    height: 7rem;
    // background-color: red;

    .navbar-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-left: 18rem;
        margin-right: 18rem;

        .add-post{
            display: flex;
            align-items: center;
            gap: 1rem;
            border: 2px solid gray;

            .add-info{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 7rem;
                height: 4rem;
                background-color: gray;
                color: white;
                font-size: 1.4rem;
            }

            input{
                width: 15rem;
                height: 3rem;
                padding-left: 1rem;
                font-size: 1.2rem;
                border-radius: .6rem;
            }

            .send-post{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 7rem;
                height: 4rem;
                background-color: rgb(24, 102, 24);
                color: white;
                font-size: 1.7rem;
            }
        }

        .search{
            display: flex;
            align-items: center;
            gap: 1.8rem;

            .user-name{
                font-size: 1.5rem;
                color: rgb(184, 42, 42);
            }
            
            input{
                width: 27.3rem;
                height: 3.5rem;
                background: #F5F5F5 0% 0% no-repeat padding-box;
                border: 1px solid #E6E6E6;
                border-radius: 2.5rem;
                padding: 2rem;
                font-size: 1.4rem;
                background: url("../../constants/icons/search.png") no-repeat scroll 1.6rem;
                padding-left: 4.5rem;
            }

            img{
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
            }
        }
    }
}




